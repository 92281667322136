import React from 'react';
import classes from '../css/MatchDetails.module.css';

function MatchDetails({ match }) {
  return (
    <div className={classes.cardDiv}>
   
   <span><strong>Date:</strong> {new Date(match.kickOff.seconds * 1000).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' })}</span>
      <p><strong>Teams:</strong> {match.homeOrAway === 'Home' ? `Arrows vs ${match.opponent}` : `${match.opponent} vs Arrows`}</p>
     
      <p><strong>Score:</strong> {match.homeOrAway === 'Home' ? `${match.yourScore} - ${match.opponentScore}` : `${match.opponentScore} - ${match.yourScore}`}</p>
      <p><strong>Location:</strong> {match.groundLocation}</p>
    </div>
  );
}

export default MatchDetails;