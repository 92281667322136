import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { getFirestore, storageBucket } from 'firebase/firestore';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCnkyeApUfJYtObYRxzW_5s0zoOjLKVFjY",
  authDomain: "arrowsfc-67c51.firebaseapp.com",
  projectId: "arrowsfc-67c51",
  storageBucket: "arrowsfc-67c51.appspot.com",
  messagingSenderId: "703565325829",
  appId: "1:703565325829:web:dabebdb66e04b7d38ade6c",
  measurementId: "G-RXQQ8F0VFV"
});

// Initialize Firebase
export const auth = firebaseApp.auth()
export const db = firebaseApp.firestore()
export const storage = firebaseApp.storage()
export default firebaseApp
