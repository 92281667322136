import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './css/NavBar.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav>
      <div className="logo">
      <img src={require("./assets/arrows-logo.png")}  alt="logo" style={{ width: 50, height: 50}} />
      <span>Barwell Arrows U11's</span>
      </div>
      <ul className={`nav-links ${isOpen ? "show-nav" : ""}`}>
        <li> <motion.div whileHover={{ scale: 1.5, rotate: 0 }}>
    <Link to="/">Home</Link>
  </motion.div></li>
        <li><a href="#">News</a></li>
        <li><Link to="/fixtures">Fixtures</Link></li>
        <li><Link to="/about">About</Link></li>
        
      </ul>
      <button className="burger" onClick={() => setIsOpen(!isOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </button>
    </nav>
  );
};

export default NavBar;