import logo from './logo.svg';
import { HashRouter as Router, Route, Routes } from "react-router-dom";


import Home from './Home';
import About from './About';
import Fixtures from './Fixtures';
import ErrorPage from './ErrorPage';
import NavBar from './NavBar';
import { motion } from 'framer-motion';
import './css/App.css';

function App() {

  return (
    <Router>
    <NavBar />
    <div className={"theMainDiv"}>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/fixtures" element={<Fixtures />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      </div>
  </Router>
    
  );
}

export default App;
