import logo from './logo.svg';
import NavBar from './NavBar';
import { motion } from 'framer-motion';
import './css/App.css';

function Home() {
  
  const currentDate = new Date().toLocaleDateString();
  return (
    <div className="App">
     
      <header className="App-header">
      <motion.img
         whileHover={{ scale: 1.1, rotate: 360 }}
         width={200}
          height={200}
         transition={{ duration: 0.3 }}
         
          src={require("./assets/arrows-ball.png")} 
          alt="logo"
        />
       <p>
           Welcome to the team! <br />
           <br />
            Site currently under construction. <br />
            {currentDate}
        </p>
      
       
      </header>
      
      <div className="ticker">
        <div className="ticker-text"><img className='App-logo' src={require("./assets/arrows-ball.png")} height={20} />
        <span>Next match: Kirby Muxloe. 28th May 6pm (away). - Up the Arrows!</span><img src={require("./assets/info.png")} height={20} /><img className='App-logo' src={require("./assets/arrows-ball.png")} height={20} />
        <span>Next training session: Wednesday 29th May 530pm</span></div>
      </div>
    </div>
  );
}

export default Home;
