import React from "react";
import classes from "./css/About.module.css";

const About = () => {
    console.log("Running About");
  return (
    <div className={classes.page}>
      <div className={classes.container}>
        <p>
          <h3>About Barwell Arrows U11's</h3>
          Welcome to the official website of Barwell Arrows U11's, an
          enthusiastic and dynamic football team dedicated to fostering a love
          for the beautiful game among young players.
          <h3>Our Journey</h3>
          Formed three years ago, Barwell Arrows U11's has quickly grown into a
          vibrant community where both boys and girls come together to develop
          their skills, make lasting friendships, and enjoy the thrill of
          football. This season marks an exciting new chapter for us as we
          embark on a fresh challenge after moving clubs. Our team remains
          committed to maintaining the same spirit of camaraderie and dedication
          that has defined us from the beginning.
          <h3>Our Philosophy</h3>
          At Barwell Arrows U11's, we believe that football is more than just a
          sport, it's a way to instill values such as teamwork, discipline, and
          perseverance in our young athletes. We strive to create a positive and
          inclusive environment where every player feels valued and encouraged
          to reach their full potential. Our friendly approach ensures that
          every child, regardless of their skill level, has the opportunity to
          grow and enjoy the game.
          <h3>Our Team</h3>
          Our team is a diverse mix of boys and girls, all sharing a common
          passion for football. Under the guidance of experienced and supportive
          coaches, our players learn the fundamentals of the sport, develop
          their abilities, and gain confidence on and off the pitch. We take
          pride in our players' progress and the strong sense of community that
          we have built together.
          <h3>Our Coaches</h3>
          Our dedicated coaching staff is the backbone of Barwell Arrows U11's.
          Leading the way is Head Coach and founder Jamie Neale, whose vision
          and passion have been instrumental in shaping the team. Supporting him
          is Deputy Coach Brad, who brings a wealth of knowledge and enthusiasm
          to every training session. Rounding out our coaching team are
          Assistant Coaches Andy and Dale, both of whom are committed to helping
          each player achieve their best. Together, our coaches create a
          supportive and engaging environment that nurtures both the skills and
          the love for football in our young athletes.
          <h3>Join Us</h3>
          Whether you're a parent looking for a supportive and engaging team for
          your child, or a young football enthusiast eager to join a team where
          fun and learning go hand in hand, Barwell Arrows U11's welcomes you.
          Come and be part of our exciting journey as we continue to grow,
          learn, and achieve new heights in the world of football. Thank you for
          visiting our website. We look forward to seeing you on the field!
        </p>
      </div>
    </div>
  );
};

export default About;
