import React, { useEffect, useState } from 'react';
import { db } from './helpers/myFirebase';
import MatchDetails from './components/MatchDetails';
import classes from './css/Fixtures.module.css';

const Fixtures = () => {
    const [fixtures, setFixtures] = useState([]);
 console.log("Running Fixtures");

const getFixtures = async () => {
    const response = await db.collection("Matches").get();
    const data = response.docs.map((doc) => doc.data());
    setFixtures(data);
    console.log(data);
}
useEffect(() => {
    getFixtures();
}
, []);



    return (
        <div className={classes.page}>
            <h1>Fixtures</h1>
            {/* Add your fixtures content here */}
         
                {fixtures.map((fixture, index) => (
                   
                        <MatchDetails match={fixture}></MatchDetails>
                    
                ))}
            
        </div>
    );
};

export default Fixtures;